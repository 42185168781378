// prefer default export if available
var preferDefault = function preferDefault(m) {
  return m && m.default || m;
};

exports.components = {
  "component---presentation-mdx": function componentPresentationMdx() {
    return import("./../../../../../presentation.mdx"
    /* webpackChunkName: "component---presentation-mdx" */
    );
  }
};